import React, { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Input } from "baseui/input";
import axios from "axios";
import { ApplicationContext } from "../../../../context/ApplicationContextProvider";
import { useLocation, useNavigate } from "react-router-dom";
import "./insurance.css";
import BenefitsDetails from "./BenefitsDetails";
import InsurancePdf from "./InsurancePdf";
import { Spinner, SIZE } from "baseui/spinner";
import ExtraLargeSpinner from "../../../../utils/ExtraLargeSpinner";
import defaultStyle from "./insuranceSpinner.module.css";


const InsurancePage = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const inputRefs = useRef([]);
  const { insurance, setInvoiceData } = useContext(ApplicationContext);
  const [successScreen, setSuccessScreen] = useState(false);
  const [trackPaymentError, setTrackPaymentError] = useState(false);
  const [dailyBookLoading, setDailyBookLoading] = useState(false);
  const [trackDailyPayment, setTrackDailyPayment] = useState(false);
  const [bookinngError, setBookingError] = useState(false);
  const [ordersLoading, setOrdersLoading] = useState(false);
  const [insuranceLoading, setInsuranceLoading] = useState(false);

  const [insuranceData, setInsuranceData] = useState({
    name: "",
    mobile: "",
    email : "",
    pre_existing_disease : "",
    imei_number: insurance.imei_number,
    driver_id: insurance.driver_id,
  });
  const navigate = useNavigate();
  const RazorPayApiKey = process.env.REACT_APP_RAZORPAY_KEY;

  useEffect(() => {
    if (insurance.lead_status === "1") {
      navigate("/ins-form-fill");
    } else if (insurance.lead_status === "3") {
      const invoice = {
        insurance_id: params.get("insurance_id"),
        validity: params.get("validity"),
        document : params.get("document")
      };
      setInvoiceData((prev) => ({
        ...prev,
        ...invoice,
      }));
      navigate("/ins-invoice");
    }
  }, [insurance.lead_status, navigate]);

  useEffect(() => {
    if (insurance.name) {
      setInsuranceData((prevData) => ({
        ...prevData,
        name: insurance.name,
      }));
    }
    if (insurance.mobile) {
      setInsuranceData((prevData) => ({
        ...prevData,
        mobile: insurance.mobile,
      }));
    }

    if (insurance.email) {
      setInsuranceData((prevData) => ({
        ...prevData,
        email: insurance.email,
      }));
    }

    if (insurance.pre_existing_disease) {
      setInsuranceData((prevData) => ({
        ...prevData,
        pre_existing_disease: insurance.pre_existing_disease,
      }));
    }
  }, [insurance.name, insurance.mobile , insurance.pre_existing_disease , insurance.email]);

  useEffect(() => {
    const adjustViewportHeight = () => {
      const viewportHeight = window.innerHeight;
      document.documentElement.style.setProperty(
        "--vh",
        `${viewportHeight * 0.01}px`
      );
    };

    window.addEventListener("resize", adjustViewportHeight);
    adjustViewportHeight();

    return () => window.removeEventListener("resize", adjustViewportHeight);
  }, []);


  //-----functionality start here -------

  //  when user click the button they first empty input tag

  //-----functionality end here ---------

  function identifierInputField (inputFieldEmptyOrNot){

    if (inputFieldEmptyOrNot) {
      inputFieldEmptyOrNot.scrollIntoView({ behavior: 'smooth', block: 'center' });
      setTimeout(() => {
        inputFieldEmptyOrNot.focus();
      }, 100);
    }
  }
  const handleChange = () => {

    if(insuranceData.name === ""){
      const inputFieldEmptyOrNot = inputRefs.current[0]; 
      identifierInputField(inputFieldEmptyOrNot)
    }else if(insuranceData.mobile === ""){
      const inputFieldEmptyOrNot = inputRefs.current[1]; 
      identifierInputField(inputFieldEmptyOrNot)
    }else  if(insuranceData.email === "") {
      const inputFieldEmptyOrNot = inputRefs.current[2]; 
      identifierInputField(inputFieldEmptyOrNot)
    }else{
      const inputFieldEmptyOrNot = inputRefs.current[3]; 
      identifierInputField(inputFieldEmptyOrNot)
    }

  };
  

  const handleContactChange = (e) => {
    let value = e.target.value.replace(/[^0-9]/g, "");
    if (value.length <= 10 && (value.length === 0 || value[0] !== "0")) {
      setInsuranceData((prevData) => ({
        ...prevData,
        mobile: value,
      }));
    }
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    if (/^[a-zA-Z ]*$/.test(value) || value === "") {
      setInsuranceData((prevData) => ({
        ...prevData,
        name: value,
      }));
    }
  };


  const handleEmailChange = (e) => {
    const value = e.target.value;
    setInsuranceData((prevData) => ({
      ...prevData,
      email: value,
    }));
  };


  const handleDiseaseChange = (e) => {
    const value = e.target.value;
    if(value.trim() !== "" || value.length === 0){
      setInsuranceData((prevData) => ({
        ...prevData,
        pre_existing_disease: value,
      }));
    } else{
      toast.error("Please enter valid text without leading or trailing spaces.", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return
    }

  };

  // form handling start here

  const handleInsuranceDetails = () => {

    if (insuranceData.mobile.length !== 10) {
      toast.error("Mobile number must be exactly 10 digits", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }else {
      insuranceData.imei_number = insurance.imei_number;
      insuranceData.driver_id = insurance.driver_id;
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_WEBSITE_URL}/web/insurance-lead/`,
        headers: {
          "Content-Type": "application/json", // Set appropriate headers
          // Add any other headers as needed
        },
        data: insuranceData,
      };
      axios(config)
        .then((response) => {
          setSuccessScreen(true);
          setInsuranceLoading(true);
          if (response.data.status === "success") {
            setTrackDailyPayment(false);
            setDailyBookLoading(false);
            setTrackPaymentError(false);
            toast.success("The cost is Rs 250 (including GST) for a 30-day period", {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            navigate("/ins-form-fill");
          } else if (response.data.status === "error") {
            setTrackDailyPayment(false);
            setDailyBookLoading(false);
            setBookingError(true);
            toast.error(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }); 
          }
        })
        .catch((error) => {
          setDailyBookLoading(false);
          setTrackDailyPayment(false);
          toast.error(error.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          return;
        });
    }
  };

  // RazorPay code start here 

  const handleInsuranceAdvancePayment = (e) => {
    setInsuranceLoading(true)
    e.preventDefault();
    
    insuranceData.imei_number = insurance.imei_number;
    insuranceData.driver_id = insurance.driver_id;
    insuranceData.type = "driver";
    insuranceData.user_id = insurance.driver_id;
    insuranceData.amount = 250;
    insuranceData.currency = "INR";
    insuranceData.purpose = "driver_insurance";

    axios({
      method: "POST",
      url: `${process.env.REACT_APP_WEBSITE_URL}/orders/`,
      withCredentials: false,
      
      data  : insuranceData ,
      headers: {
        "Content-Type": `application/json`,
      },
    })
      .then(({ data }) => {
        if (data.status === "created") {
          setOrdersLoading(false);
          showRazorpay(data.order_id);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function showRazorpay(Order_ID) {
    // sendingDailyBookingData.order_id = Order_ID;
    setInsuranceLoading(false);
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    ); // Don't Remove where razorpay payment integration is there

    if (!res) {
      console.error("Razorpay script did not load correctly");
      return;
    }
    const options = {
      key: RazorPayApiKey,
      name: "DriveU",
      description: "DU Daily",
      image:
        "https://ik.imagekit.io/driveu/Home_Screen/HjINSPmw0xOlWU_medium_JTMcNKBFl.png",
      order_id: Order_ID,
      handler: function(response) {
        const obj = {
          razorpay_order_id: response.razorpay_order_id,
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_signature: response.razorpay_signature,
        };
        if (response.razorpay_signature === "") {
          setTrackPaymentError(true);
          toast.error("Razorpay signature is not found");
        } else {
          setDailyBookLoading(true);
          setTrackDailyPayment(true);
          checkingStatus(obj);
        }
      },
      prefill: {
        email: insuranceData.email,
        contact: insuranceData.mobile,
      },
      config: {
        display: {
          blocks: {
            banks: {
              name: "Methods with Offers",
              instruments: [
                {
                  method: "wallet",
                  wallets: ["ola money"],
                },
              ],
            },
          },
          hide: [
            { method: "netbanking" },
            { method: "wallet" },
            { method: "paylater" },
            { method: "cardless_emi" },
          ],
          sequence: ["block.banks"],
          preferences: {
            show_default_blocks: true,
          },
        },
      },
      theme: {
        color: "#127e40",
      },
      modal: {
        ondismiss: function() {
          if ("Are you sure, you want to close the form?") {
            // setLoaderPayment(false);
            // setPaymentErr(true);
            setTrackPaymentError(true);
          } else {
            // setLoaderPayment(true);
            // setPaymentErr(false);
            setTrackPaymentError(false);
          }
        },
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }
  function checkingStatus(obj) {

    setTimeout(() => {
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_WEBSITE_URL}/orders/status/?src=website`,
        data: obj,
        headers: {
          "Content-Type": `application/json`,
        },
      })
        .then(({ data }) => {
          if (data.status === "success") {
            setTrackPaymentError(false);
            insuranceData.order_id = obj.razorpay_order_id;
            handleInsuranceDetails();
            setInsuranceLoading(false);
          } else{
            setDailyBookLoading(false);
            setTrackDailyPayment(false);
            setTrackPaymentError(true);
          }
        })
        .catch((err) => {
          setDailyBookLoading(false);
          setTrackDailyPayment(false);
          setTrackPaymentError(true);
          console.log(err.message);
        });
    }, 3000);
  }

  return (
    <>
      <div className="bg-white text-gray-900 w-[90%] m-auto">
        <div className="max-w-7xl mx-auto p-2">
          {/* ======Heading Section========= */}

          <div className="mb-4">
            <div>
              {" "}
              {/*className={styles.removeInMobile} */}
              {/* <figure>
                <img
                  src="https://www.driveu.in/car-care/wp-content/uploads/2023/11/400x151-DriveU-Car-Care-Logo.png"
                  alt="DriveU Logo"
                  className="w-40 h-22 ml-[-6px]"
                />
              </figure> */}
            </div>
            <p className="text-[16px] sm:text-[16px] md:text-[20px] mb-1">
              Get exclusive health protection plan
            </p>
            <p className="text-sm mb-4">
              powered by Reliance General Insurance{" "}
            </p>

            <button
              onClick={handleChange}
              className=" bg-primaryGreen p-2 px-3 text-xs text-white rounded-3xl"
            >
              Get In touch with DriveU Team
            </button>
          </div>

          {/*===== Image Section===== */}

          <hr className="border-1 border-black" />

          {/* =====Benefits Section==== */}

          <div className="mb-4 mt-4">
            <h2 className="text-xl font-bold mb-2">
              <strong>How does it work?</strong>
            </h2>

            {/* ====List of benefits===== */}

            <div className="flex flex-wrap mt-2 flex-col items-start">
              {/* Repeat this block for each feature */}
              <div className="flex justify-center items-center">
                <div className="w-6 h-6 sm:w-6 sm:h-6 md:h-8 md:w-8  flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    width="20"
                    height="20"
                  >
                    <path
                      fill="currentColor"
                      d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"
                    ></path>
                  </svg>
                </div>

                <p className="text-[14px] text-left ml-5">
                  Submit your name and phone number
                </p>
              </div>

              <div className="flex justify-center items-center">
                <div className="w-6 h-6 sm:w-6 sm:h-6 md:h-8 md:w-8 flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    width="20"
                    height="20"
                  >
                    <path
                      fill="currentColor"
                      d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"
                    ></path>
                  </svg>
                </div>

                <p className="text-[14px] text-left ml-5">
                  DriveU team will call you to confirm the details
                </p>
              </div>

              <div
                className="flex justify-center items-start"
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                <div className="w-6 h-6 sm:w-6 sm:h-6 md:h-8 md:w-8 flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    width="20"
                    height="20"
                  >
                    <path
                      fill="currentColor"
                      d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"
                    ></path>
                  </svg>
                </div>

                <p className="text-[14px] text-left ml-5">
                  Cost: Rs 250(including GST) for 30 days
                </p>
              </div>
            </div>
          </div>

          <BenefitsDetails />

          <InsurancePdf />

          <hr className="border-1 border-black" />

          {/* =======Contact Form Section ===*/}

          <div className="mb-10">
            <h2 className="text-md font-bold mb-4 mt-4">
              <strong>Get in touch with DriveU for Insurance?</strong>
              <p className="text-xs">The cost is Rs 250 (including GST) for a 30-day period</p>
            </h2>

            {!successScreen && (
              <div>
                <form
                  onSubmit={handleInsuranceAdvancePayment}
                  className={`${successScreen ? "hidden" : "block"}`}
                >
                  <Input
                    overrides={{
                      Root: {
                        style: (props) => {
                          const {
                            $disabled,
                            $error,
                            $isFocused,
                            $theme: { colors, sizing },
                          } = props;
                          const border = $disabled
                            ? colors.borderTransparent
                            : $error
                            ? colors.borderNegative
                            : $isFocused
                            ? "darkseagreen"
                            : colors.borderOpaque;
                          return {
                            borderLeftColor: border,
                            borderRightColor: border,
                            borderTopColor: border,
                            borderBottomColor: border,
                            boxShadow: `0 0 ${sizing.scale100} ${
                              $disabled
                                ? "transparent"
                                : $error
                                ? colors.shadowError
                                : $isFocused
                                ? "lightseagreen"
                                : "transparent"
                            }`,
                          };
                        },
                        props: {
                          className: "mb-4",
                        },
                      },
                    }}
                    id = "inputField1"
                    className = "inputField"
                    inputRef={(ref) => (inputRefs.current[0] = ref)}
                    value={insuranceData.name}
                    placeholder="Enter Your Name"
                    onChange={handleNameChange}
                  />

                  <Input
                    type="number"
                    overrides={{
                      Root: {
                        style: (props) => {
                          const {
                            $disabled,
                            $error,
                            $isFocused,
                            $theme: { colors, sizing },
                          } = props;
                          const border = $disabled
                            ? colors.borderTransparent
                            : $error
                            ? colors.borderNegative
                            : $isFocused
                            ? "darkseagreen"
                            : colors.borderOpaque;
                          return {
                            borderLeftColor: border,
                            borderRightColor: border,
                            borderTopColor: border,
                            borderBottomColor: border,
                            boxShadow: `0 0 ${sizing.scale100} ${
                              $disabled
                                ? "transparent"
                                : $error
                                ? colors.shadowError
                                : $isFocused
                                ? "lightseagreen"
                                : "transparent"
                            }`,
                          };
                        },
                        props: {
                          className: "mb-4",
                        },
                      },
                    }}
                    id = "inputField2"
                    className = "inputField"
                    inputRef={(ref) => (inputRefs.current[1] = ref)}
                    value={insuranceData.mobile}
                    onChange={handleContactChange}
                    placeholder="Enter Your Phone Number"
                  />

                   <Input
                    // inputRef={(ref) => (inputRef.current = ref)}
                    // glowing effect
                    overrides={{
                      Root: {
                        style: (props) => {
                          const {
                            $disabled,
                            $error,
                            $isFocused,
                            $theme: { colors, sizing },
                          } = props;
                          const border = $disabled
                            ? colors.borderTransparent
                            : $error
                            ? colors.borderNegative
                            : $isFocused
                            ? "darkseagreen"
                            : colors.borderOpaque;
                          return {
                            borderLeftColor: border,
                            borderRightColor: border,
                            borderTopColor: border,
                            borderBottomColor: border,
                            boxShadow: `0 0 ${sizing.scale100} ${
                              $disabled
                                ? "transparent"
                                : $error
                                ? colors.shadowError
                                : $isFocused
                                ? "lightseagreen"
                                : "transparent"
                            }`,
                          };
                        },
                        props: {
                          className: "mb-4",
                        },
                      },
                    }}
                    type = "email"
                    inputRef={(ref) => (inputRefs.current[2] = ref)}
                    id = "inputField3"
                    className = "inputField"
                    value={insuranceData.email}
                    placeholder="Enter Your Email Id"
                    onChange={handleEmailChange}
                    required
                  /> 


                  <Input
                    // inputRef={(ref) => (inputRef.current = ref)}
                    // glowing effect
                    overrides={{
                      Root: {
                        style: (props) => {
                          const {
                            $disabled,
                            $error,
                            $isFocused,
                            $theme: { colors, sizing },
                          } = props;
                          const border = $disabled
                            ? colors.borderTransparent
                            : $error
                            ? colors.borderNegative
                            : $isFocused
                            ? "darkseagreen"
                            : colors.borderOpaque;
                          return {
                            borderLeftColor: border,
                            borderRightColor: border,
                            borderTopColor: border,
                            borderBottomColor: border,
                            boxShadow: `0 0 ${sizing.scale100} ${
                              $disabled
                                ? "transparent"
                                : $error
                                ? colors.shadowError
                                : $isFocused
                                ? "lightseagreen"
                                : "transparent"
                            }`,
                          };
                        },
                        props: {
                          className: "mb-4",
                        },
                      },
                    }}
                    inputRef={(ref) => (inputRefs.current[3] = ref)}
                    id = "inputField4"
                    className = "inputField"
                    type="text"
                    value={insuranceData.pre_existing_disease}
                    placeholder="Enter Your Pre Existing Disease(if any)"
                    onChange={handleDiseaseChange}
                    required
                  />
                  {/* <Button shape={SHAPE.pill} colors  = {{backgroundColor: '#006D35',color: 'white' }}>Submit</Button> */}
                  <button
                    className={`bg-primaryGreen px-4 py-2  text-white rounded-3xl`}
                    // id="inputField"
                  >
                    {insuranceLoading  ? <Spinner $size={SIZE.small}/>  :  "Submit"}
                  </button>
                </form>
              </div>
            )}
          </div>
        </div>
        {trackDailyPayment && dailyBookLoading ? (
          <div className={defaultStyle.overlay}>
            <ExtraLargeSpinner />
          </div>
        ) : null}
        {ordersLoading ? (
          <div className={defaultStyle.overlay}>
            <ExtraLargeSpinner />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default React.memo(InsurancePage);
