import React from 'react'
import BenefitsInsuranceTable from './BenefitsInsuranceTable'

const BenefitsDetails = () => {
  return (
    <div className="mb-4 mt-4">
    <h2 className="text-xl font-bold mb-2">
      <strong>Benefits</strong>
    </h2>

    {/* ====List of benefits===== */}

    <div className="flex flex-wrap mt-2 flex-col items-start">
      {/* Repeat this block for each feature */}
      <div className="flex justify-center items-center mb-2">
        <div className="w-6 h-6 sm:w-6 sm:h-6 md:h-8 md:w-8  flex items-center justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            width="20"
            height="20"
          >
            <path
              fill="currentColor"
              d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"
            ></path>
          </svg>
        </div>

        <p className="text-[14px] text-left ml-5 leading-[1.1rem] ">
        Instant Coverage: No waiting periods-get coverage from day one
        </p>
      </div>

      <div className="flex justify-center items-center mb-2">
        <div className="w-6 h-6 sm:w-6 sm:h-6 md:h-8 md:w-8 flex items-center justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            width="20"
            height="20"
          >
            <path
              fill="currentColor"
              d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"
            ></path>
          </svg>
        </div>

        <p className="text-[14px] text-left ml-5 leading-[1.1rem]">
        Comprehensive Coverage: High room rent capping, Day care treatments, modern treatments etc
        </p>
      </div>

      <div
        className="flex justify-center items-start mb-2" 
        style={{ justifyContent: "center", alignItems: "center" }}
      >
        <div className="w-6 h-6 sm:w-6 sm:h-6 md:h-8 md:w-8 flex items-center justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            width="20"
            height="20"
          >
            <path
              fill="currentColor"
              d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"
            ></path>
          </svg>
        </div>

        <p className="text-[14px] text-left ml-5 leading-[1.1rem]">
        Dedicated support: Exclusive support services for all members
        </p>
      </div>
    </div>
    <BenefitsInsuranceTable/>
    {/* 
  <Button
    variant="contained"
    color="success"
    sx={{
      marginTop: "1rem",
      backgroundColor: "black",
      "&:hover": { backgroundColor: "darkgray" },
    }}
    onClick={() => smoothScrollToElement("target-element")}
  >
    Explore Ads
  </Button> */}
  </div>
  )
}

export default BenefitsDetails
