import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'baseui/modal';
import { Spinner,SIZE } from "baseui/spinner";
import "./insurancePdf.css"

const PDFModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const openModal = (e) => {
    e.preventDefault();
    if (document.activeElement) {
      document.activeElement.blur();
    }
    setIsOpen(true);
    setLoading(true); // Reset loading state when opening the modal
  };

  const closeModal = () => {
    setIsOpen(false);
  };


  return (
    <div>
      <p onClick={openModal} className="mb-4 text-md text-blue-700 underline">
        Please read the policy wordings for complete t&c
      </p>

      <Modal
        isOpen={isOpen}
        onClose={closeModal}
        overrides={{
          ModalBackdrop: {
            style: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        }}
      >
        <ModalHeader>Policy Wordings</ModalHeader>
        <ModalBody className="e5">
           {loading && (
            <div className = "flex justify-center items-center">
              
              <Spinner $size={SIZE.small} />

            </div>
          )}
          <iframe
            loading="eager"
            src="https://docs.google.com/gview?url=https://driveu-zappa-static.s3.ap-south-1.amazonaws.com/Group_Mediclaim_Policy_Wording.pdf&embedded=true"
            style={{ width: "100%", height: "600px", border: "none", display: loading ? "none" : "block" ,    minHeight: "400px",
            maxHeight: "80vh"}}
            title="Policy Wordings"
            onLoad={() => setLoading( false )} 
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default PDFModal;
