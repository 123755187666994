import React, { createContext } from "react";
import { useState } from "react";
export const ApplicationContext = createContext();
function ApplicationContextProvider({ children }) {
  const [driverData, setDriverData] = useState({
    driver_id: "",
    imei_number: "",
    lat: "",
    lng: "",
    email: "",
    mobile: "",
    name: "",
    city_name: "",
    driver_zone: "",
    show_quiz: false,
  });
  const [partnerBookData, setPartnerBookData] = useState([]);
  const [quizLang, setQuizLang] = useState("");
  const [quizzes, setQuizzes] = React.useState([]);
  const [isQuizLoading, setIsQuizLoading] = useState(true);
  const [quizError, setQuizError] = useState("");
  const [insurance,setInsurance] = useState([]);
  const [invoiceData,setInvoiceData] = useState({});

  return (
    <ApplicationContext.Provider
      value={{
        partnerBookData,
        setPartnerBookData,
        driverData,
        setDriverData,
        quizLang,
        setQuizLang,
        quizzes,
        setQuizzes,
        isQuizLoading,
        setIsQuizLoading,
        quizError,
        setQuizError,
        insurance,
        setInsurance,
        invoiceData,
        setInvoiceData
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
}

export default ApplicationContextProvider;
