import React, { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../../../../context/ApplicationContextProvider";
import { TbDownload } from "react-icons/tb";
import { Spinner, SIZE } from "baseui/spinner";
import { Modal, ModalBody } from "baseui/modal";
import "./insuranceInvoice.css"

const InsuranceInvoice = () => {
  const { invoiceData } = useContext(ApplicationContext);
  const [iframeVisible, setIframeVisible] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [modalOpen , setModalOpen] = useState(false);


  function close() {
    setIsOpen(false);
    setModalOpen(false);
  }
  const iframePageOpen = () => {
    setIsOpen(true);
    setIframeVisible(!iframeVisible);
    if (!iframeVisible) {
      setLoading(true); // Start loading when iframe becomes visible
    }
  };

  const handleLoad = () => {
    setLoading(false); // Hide loading when iframe finishes loading
  };

  const handleDownlaod = () =>{
    setModalOpen(true);
  }

  return (
    <>
      <div>
        <h1 className="pl-4 pt-4 pb-6 text-xl font-extrabold">
          Health Protection Plan
        </h1>
      </div>
      <div>
        <h3 className="px-4 text-sm text-gray-400">UHID</h3>
        <p className="px-4 text-sm">{invoiceData.insurance_id}</p>
      </div>
      <div>
        <h3 className="px-4 pt-4 text-sm text-gray-400">Validity</h3>
        <p className="px-4 text-sm">{invoiceData.validity}</p>
      </div>
      <div>
        <h3 className="px-4 pt-4 text-sm text-gray-400">
          Health Protection Plan Card
        </h3>
        <div className="pl-4 flex cursor-pointer">
          <a
            target="_blank"
            href
            onClick={()=>handleDownlaod()}
            className="flex items-end text-primaryGreen font-extrabold"
          >
            <TbDownload className="mr-1" />
            <span className="text-sm underline underline-offset-4">
              Download Here
            </span>
          </a>
        </div>
      </div>

      <div>
        <h3 className="px-4 pt-4 text-sm text-gray-400">List of Hospitals</h3>
        <p
          className="px-4 text-sm text-primaryGreen font-extrabold underline underline-offset-4 cursor-pointer"
          onClick={iframePageOpen}
        >
          Open Hospitals List
        </p>

        {iframeVisible && (
          <Modal  onClose={close} isOpen={isOpen}>
            <ModalBody>
              <div
                className=" mb-6"
                style={{
                  position: "relative",
                  height: isLoading ? "300px" : "500px",
                }}
              >
                {isLoading && (
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)", // Centering
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Spinner $size={SIZE.small} />
                  </div>
                )}
                <iframe
                  src="https://rgi-locator.appspot.com/?Search_by=hospital&sourcesystem=website&phonenumber=&emailid=#/"
                  width="100%"
                  height="500px"
                  title="List Of Hospitals"
                  onLoad={handleLoad} // Trigger onLoad when iframe finishes loading
                  style={{ display: isLoading ? "none" : "block" }} // Hide iframe until loaded
                />
              </div>
            </ModalBody>
          </Modal>
        )}
      </div>
        {
          modalOpen && <iframe
          style={
            {
              display: "none"
            }
          }
            src={invoiceData?.document }
            width="100%"
            height="100%"
            title="PDF Viewer"
            onLoad={handleLoad}
          />
        }
    </>
  );
};

export default InsuranceInvoice;



