import React from "react";

const BenefitsInsuranceTable = () => {
  return (
    <div className="mt-5">
      <table class="w-full sm:w-full md:w-full lg:w-2/3 xl:w-2/3 border-collapse border border-spacing-2 border-slate-500 hover:border-collapse text-xs">
        <thead>
          <tr>
            <th class="border border-slate-600 p-3">Benefits</th>
            <th class="border border-slate-600 p-3">Insurance</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="border border-slate-700 p-3">Sum Insured (SI)</td>
            <td class="border border-slate-700 p-3">2 Lakh</td>
          </tr>
          <tr>
            <td class="border border-slate-700 p-3">Family Definition</td>
            <td class="border border-slate-700 p-3">Self</td>
          </tr>
          <tr>
            <td class="border border-slate-700 p-3">
              Pre & Post hospitalization expenses cover
            </td>
            <td class="border border-slate-700 p-3">60 days</td>
          </tr>
          <tr>
            <td class="border border-slate-700 p-3">
              Domiciliary Hospitalisation
            </td>
            <td class="border border-slate-700 p-3">30% of SI</td>
          </tr>
          <tr>
            <td class="border border-slate-700 p-3">
              Pre Existing diseases covered from day one
            </td>
            <td class="border border-slate-700 p-3">Yes</td>
          </tr>
          <tr>
            <td class="border border-slate-700 p-3">
              30 days exclusion waived off
            </td>
            <td class="border border-slate-700 p-3">Yes</td>
          </tr>
          <tr>
            <td class="border border-slate-700 p-3">Room Rent Capping</td>
            <td class="border border-slate-700 p-3">2% Normal 4% ICU</td>
          </tr>
          <tr>
            <td class="border border-slate-700 p-3">Ambulance Charges</td>
            <td class="border border-slate-700 p-3">
              2,500 per hospitalization
            </td>
          </tr>
          <tr>
            <td class="border border-slate-700 p-3">Disease wise capping</td>
            <td class="border border-slate-700 p-3">Yes</td>
          </tr>
          <tr>
            <td class="border border-slate-700 p-3">Day Care procedure</td>
            <td class="border border-slate-700 p-3">Covered</td>
          </tr>
          <tr>
            <td class="border border-slate-700 p-3">Cataract Limit</td>
            <td class="border border-slate-700 p-3">
              10% of SI, maximum INR 20,000 (whichever is lower)
            </td>
          </tr>
          <tr>
            <td class="border border-slate-700 p-3">Any other terms</td>
            <td class="border border-slate-700 p-3">
              Modern treatments cover up to 50% of SI
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default BenefitsInsuranceTable;
