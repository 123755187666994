import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./cleverTapConfig";
import reportWebVitals from "./reportWebVitals";
import { BaseProvider, LightTheme } from "baseui";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import "mapbox-gl/dist/mapbox-gl.css";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import ApplicationContextProvider from "./context/ApplicationContextProvider";
import DuDailyPartnerLandingPage from "./pages/DuDailyPartnerPage/DuDailyPartnerLandingPage";
import AllTypesBooking from "./pages/DuDailyPartnerPage/AllTypesBooking";
import StatusPackage from "./pages/DuDailyPartnerPage/StatusPackage";
import AssignedPartnerPage from "./pages/DuDailyPartnerPage/AssignedPartnerPage";
import QuizLandingPage from "./pages/DuQuizPage/QuizLandingPage";
import QuizStartPage from "./pages/DuQuizPage/QuizStartPage";
import InsurancePage from "./components/Mobile/DuDailyPartner/DuDailyPartnerInsurancePage/InsurancePage";
import InsuranceFromFill from "./components/Mobile/DuDailyPartner/DuDailyPartnerInsurancePage/InsuranceFromFill";
import InsuranceInvoice from "./components/Mobile/DuDailyPartner/DuDailyPartnerInsurancePage/InsuranceInvoice";
const engine = new Styletron();
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route
        path="/du-daily-partner/"
        element={<DuDailyPartnerLandingPage />}
      />
      <Route
        path="/du-daily-partner-all-bookings"
        element={<AllTypesBooking />}
      />
      <Route path="/du-daily-partner-status" element={<StatusPackage />} />
      <Route
        path="/du-daily-partner-assigned"
        element={<AssignedPartnerPage />}
      />
      <Route path="/insurance" element={<InsurancePage/>}/>
      <Route path="/ins-form-fill" element={<InsuranceFromFill/>}/>
      <Route path="/ins-invoice" element={<InsuranceInvoice/>}/>


      <Route path="/du-daily-partner-quiz" element={<QuizLandingPage />} />
      <Route path="/du-daily-partner-quiz-start" element={<QuizStartPage />} />
    </Route>
  )
);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <StyletronProvider value={engine}>
    <BaseProvider theme={LightTheme}>
      {/* <Suspense fallback={<>Loading...</>}> */}
      <ApplicationContextProvider>
        <RouterProvider router={router} />
      </ApplicationContextProvider>
      {/* </Suspense> */}
    </BaseProvider>
  </StyletronProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
